import IconSVG from '../../styles/svg-icons';
import { documentsActions } from '../../actions';
import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';
import { useAppDispatch } from '../../effects/useAppDispatch';

interface Props {
    isinCusip: string;
    textVisible?: boolean;
    text?: string;
    requiredFeature?: SubscriptionFeature;
    disabled?: boolean;
}

export function DownloadDocuments({ isinCusip, textVisible = true, disabled, text = "Docs" }: Props) {
    const dispatch = useAppDispatch();

    return (
        <button
            className="btn-link"
            type="button"
            disabled={disabled}
            onClick={() => dispatch(documentsActions.downloadSecurityDocs(isinCusip))}
        >
            <IconSVG name="downloadTemplate" width={16} height={16} />
            {textVisible && <span>{text}</span>}
        </button>
    );
}
